/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { requests } from './requests'

export const util = {
  getAge,
  dateMonth,
  formatDate,
  getMatchStatuses,
  uploadFile,
  uploadPartnerPhoto,
  getDefaultAvailabilities,
  getDefaultTimeslots,
}

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function getAge(birthDateString) {
  const today = new Date()
  const birthDate = new Date(birthDateString)

  const yearsDifference = today.getFullYear() - birthDate.getFullYear()

  if (
    today.getMonth() < birthDate.getMonth()
        || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
  ) {
    return yearsDifference - 1
  }

  return yearsDifference
}

function dateMonth(time) {
  const d = new Date(time)
  return `${(`0${d.getDate()}`).slice(-2)}-${(`0${d.getMonth() + 1}`).slice(-2)}`
}

export function formatDate(format, time) {
  if (time == null) {
    return 'null'
  }
  const d = new Date(time)

  return format
    .replace('yyyy', d.getFullYear())
    .replace('MM', (`0${d.getMonth() + 1}`).slice(-2))
    .replace('dd', (`0${d.getDate()}`).slice(-2))
    .replace('hh', (`0${d.getHours()}`).slice(-2))
    .replace('mm', (`0${d.getMinutes()}`).slice(-2))
    .replace('ss', (`0${d.getSeconds()}`).slice(-2))
}

function getMatchStatuses() {
  return [
    { id: 'accepted', name: 'Accepted' },
    { id: 'planned', name: 'Planned' },
    { id: 'confirmed', name: 'Confirmed' },
    { id: 'done', name: 'Done' },
    { id: 'canceled', name: 'Canceled' },
  ]
}

/**
 * Uploads a file to the server and returns the url generated for it
 * @param file
 * @returns {Promise<unknown>}
 */
function uploadFile(file) {
  const data = new FormData()
  data.append('file', file)

  return new Promise((resolve, reject) => {
    requests.upload('files/upload', data).then(response => {
      resolve(response.data)
    }, error => {
      window.console.log(error)
      reject(error)
    })
  })
}

/**
 * Uploads a partner photo to the server and returns the url generated for it
 * @param file
 * @returns {Promise<unknown>}
 */
function uploadPartnerPhoto(file) {
  const data = new FormData()
  data.append('file', file)

  return new Promise((resolve, reject) => {
    requests.upload('files/partner-photo', data).then(response => {
      resolve(response.data)
    }, error => {
      window.console.log(error)
      reject(error)
    })
  })
}

/**
 * returns a list of all possible campaign medium types
 * @returns {string[]}
 */
function getMediums() {
  return ['tv', 'radio', 'cpc', 'news', 'social_media', 'video', 'email', 'referral', 'organic', 'other']
}

function getDefaultAvailabilities() {
  return {
    0: [[0, 1439]], 1: [[0, 1439]], 2: [[0, 1439]], 3: [[0, 1439]], 4: [[0, 1439]], 5: [[0, 1439]], 6: [[0, 1439]],
  }
}

function getDefaultTimeslots() {
  return {
    0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
  }
}
