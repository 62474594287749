<template>
  <div>
    <h2>Block specific days</h2>
    <p>Here you can block specific days or periods. You can select a day/period that starts at least 24 hours in advance
      and provide a reason so we know why you have deviating availability. If you want to block a day that starts within
      24 hours, manually cancel the reservations in your reservations screen or send us an email
      (partners@breeze.social).</p>
    <p>
      <b>Note:</b> It can take up to 60 minutes before reservations are removed from your calendar and Formitable integration.
    </p>

    <div
      v-for="(slot, index) in editData"
      :key="index"
      class="card"
    >
      <div class="card-body row">
        <div class="col-md-4 col-12">
          <div class="row">
            <div class="col-md-6 mb-1">
              <label
                for="from"
                class="on-border"
              >From</label>
              <b-datepicker
                v-model="slot.fromParsed"
                type="date"
                :min="getMin(new Date())"
                format=""
                placeholder="Add a date"
                @input="setUpdated(editData, index)"
              />
            </div>
            <div class="col-md-6 mb-1">
              <label
                for="until"
                class="on-border"
              >Until</label>
              <b-datepicker
                v-model="slot.untilParsed"
                type="date"
                :min="getMin(slot.fromDate)"
                placeholder="Add a date"
                @input="setUpdated(editData, index)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-textarea
                v-model="slot.reason"
                placeholder="Reason (optional)"
                @input="slot.updated = true"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8 col-12 pt-1 d-flex justify-content-start align-middle align-items-center">
          <div>
            <b-button
              v-if="slot.updated && slot.fromDate && slot.untilDate"
              class="mr-2"
              variant="outline-primary"
              @click="save(editData, index)"
            >
              <feather-icon
                v-if="!slot.updating"
                icon="SaveIcon"
              />
              <feather-icon
                v-if="slot.updating"
                class="spinner"
                icon="RefreshCwIcon"
              />
            </b-button>
            <b-button
              v-if="!slot.updating"
              variant="danger"
              @click="remove(editData, index)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <b-button
      class="btn btn-secondary ml-2 mb-2"
      variant="primary"
      @click="add"
    >
      +
    </b-button>
  </div>
</template>

<script>

import { LocationAvailabilityOverride } from '@/_models/location-availability-override'
import { formatDate } from '@/_helpers/util'
import { event } from 'vue-gtag'

export default {
  name: 'LocationAvailabilityOverrides',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    locationId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      editData: [],
    }
  },
  watch: {
    value: {
      handler() {
        this.setData()
      },
    },
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      this.editData = this.value.filter(override => {
        const now = new Date()
        override.fromParsed = formatDate('yyyy-MM-dd', override.fromDate)
        override.untilParsed = formatDate('yyyy-MM-dd', override.untilDate)
        return override.untilDate > now
      })
    },
    getMin(min) {
      return formatDate('yyyy-MM-dd', (min) ?? new Date())
    },
    setUpdated(editData, index) {
      this.$set(this.editData[index], 'updated', true)
      this.editData[index].fromDate = new Date(this.editData[index].fromParsed)
      this.editData[index].fromDate.setHours(0, 0, 0, 0)
      this.editData[index].untilDate = new Date(this.editData[index].untilParsed)
      if (this.editData[index].fromDate > this.editData[index].untilDate) {
        this.editData[index].untilDate = new Date(this.editData[index].fromDate.getTime())
        this.editData[index].untilParsed = formatDate('yyyy-MM-dd', this.editData[index].untilDate)
      }
      this.editData[index].untilDate.setHours(23, 59, 59, 0)
    },
    add() {
      const item = new LocationAvailabilityOverride()
      item.fromDate = new Date()
      item.fromDate.setDate(item.fromDate.getDate() + 7)
      item.untilDate = new Date()
      item.untilDate.setDate(item.untilDate.getDate() + 7)
      item.fromParsed = formatDate('yyyy-MM-dd', item.fromDate)
      item.untilParsed = formatDate('yyyy-MM-dd', item.untilDate)
      item.updated = true
      item.locationId = this.locationId
      item.reason = ''
      this.editData.push(item)
      this.setUpdated(this.editData, this.editData.length - 1)
    },
    remove(editData, index) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure?')) {
        if (this.editData[index].id) {
          this.editData[index].deleteThis()
        }
        this.editData.splice(index, 1)
      }
    },
    save(editData, index) {
      if (editData[index].fromDate > editData[index].untilDate) {
        this.$store.dispatch('alert/error', 'The until date needs to be after the from date', { root: true })
        this.$set(this.editData[index], 'updated', false)
        return
      }
      this.$set(this.editData[index], 'updating', true)

      this.editData[index].save().then(() => {
        this.$set(this.editData[index], 'updated', false)
        this.$set(this.editData[index], 'updating', false)
      })

      event('save-availability-override', {
        from: editData[index].fromDate,
        until: editData[index].untilDate,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .on-border {
    position: absolute;
    top: -8px;
    left: 20px;
    padding: 0 0.5rem;
    background: white;
    z-index: 1;
  }
</style>
